import { createRouter, createWebHistory } from 'vue-router'
import StocksView from '../views/StocksView.vue'
import SummaryView from '../views/SummaryView.vue'

const routes = [
  {
    path: '/stocks',
    name: 'stocks',
    component: StocksView
  },
  {
    path: '/',
    name: 'stocks-main',
    component: StocksView
  },
  {
    path: '/summary',
    name: 'summary',
    component: SummaryView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
