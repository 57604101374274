import { createStore } from 'vuex'
import moment from "moment";

export const getStoreData = (name) => {
	let data = localStorage.getItem(name);
	
	if (data == undefined || data == 'undefined' || typeof data == "undefined") {
		data = null;
	}
	
	if (!!data) {
		data = JSON.parse(data);
	}
	
	return data;
};

const LOCAL_STORE_STOCK = 'stock_store';
const LOCAL_STORE_PASSIVE = 'passive_store';
const LOCAL_STORE_STATUS = 'status_store';
const LOCAL_STORE_WIN = 'win_store';
const LOCAL_STORE_TIMER = 'status_timer';
const LOCAL_STORE_PlAYERS = 'players_store';
const LOCAL_STORE_SUMMARY = 'summary_store';

const defaultMember = (name = '') => ({
	name: name,
	count: '',
	price: '',
	check: false,
})

const defaultStock = (players) => ({
	name: '',
	price: '',
	percent: '',
	close: '',
	opened: true,
	my: false,
	members: players.map(item => ({ ...defaultMember(item) })),
})

const defaultPassive = () => ({
	name: '',
	sum: '',
})

const defaultPlayer = () => ({
	name: '',
	isMe: false
})

const defaultPlayers = () => [
	defaultPlayer(),
	defaultPlayer(),
	defaultPlayer(),
	defaultPlayer(),
	defaultPlayer(),
	defaultPlayer(),
	defaultPlayer(),
	defaultPlayer(),
]

const defaultSummary = () => ({
	salary: 0,
	deposit: 0,
	nalog1: 0,
	nalog2: 0,
	nalog3: 0,
	nalog4: 0,
	nalog5: 0,
	nalog6: 0,
	nalog7: 0,
	credit: 0,
})

const defaultWin = () => ({
	business: '',
	all: ''
})

const defaultState = (defaults = false) => ({
	win: defaults ? defaultWin() : getStoreData(LOCAL_STORE_WIN) || defaultWin(),
	status: defaults ? false : getStoreData(LOCAL_STORE_STATUS) || false,
	stocks: defaults ? [] : getStoreData(LOCAL_STORE_STOCK) || [],
	passives: defaults ? [] : getStoreData(LOCAL_STORE_PASSIVE) || [],
	summary: defaults ? defaultSummary() : getStoreData(LOCAL_STORE_SUMMARY) || defaultSummary(),
	timer: defaults ? {} : getStoreData(LOCAL_STORE_TIMER) || {},
	players: defaults ?
		defaultPlayers() :
		!!getStoreData(LOCAL_STORE_PlAYERS) && getStoreData(LOCAL_STORE_PlAYERS).length > 0 ?
			getStoreData(LOCAL_STORE_PlAYERS) :
			defaultPlayers(),
})

export default createStore({
	state: {
		...defaultState()
	},
	getters: {
		gameStatus: state => state.status,
		gameTimer: state => state.timer,
		gameStocks: state => state.stocks,
		gamePlayers: state => state.players,
		gamePassives: state => state.passives,
		gameWin: state => state.win,
		gameSummary: state => state.summary
	},
	actions: {
		changeWin({ commit }, data) {
			commit('changeWin', data)
		},
		changePlayers({ commit }, data) {
			commit('changePlayers', data)
		},
		changeSummary({ commit }, data) {
			commit('changeSummary', data)
		},
		clearAll({ commit }, data) {
			commit('clearAll', data)
		},
		changeStock({ commit }, data) {
			commit('changeStock', data)
		},
		changePassive({ commit }, data) {
			commit('changePassive', data)
		},
		changeMember({ commit }, data) {
			commit('changeMember', data)
		},
		startGame({ commit }) {
			commit('startGame')
		},
		
		startTimer({ commit }, time) {
			commit('startTimer', time)
		},
		
		stopGame({ commit }) {
			commit('stopGame')
		},
		pauseGame({ commit }) {
			commit('pauseGame')
		},
		addStock({ commit }) {
			commit('addStock')
		},
		addPassive({ commit }) {
			commit('addPassive')
		},
		removeStock({ commit }, index) {
			commit('removeStock', index)
		},
		removePassive({ commit }, index) {
			commit('removePassive', index)
		},
		addMember({ commit }, index) {
			commit('addMember', index)
		}
	},
	mutations: {
		clearAll(state) {
			localStorage.clear();
			
			Object.keys(defaultState()).forEach(name => {
				state[name] = defaultState()[name];
			})
		},
		
		startGame(state) {
			state.status = true
			
			localStorage.setItem(LOCAL_STORE_STATUS, state.status);
		},
		
		startTimer(state, time) {
			let timer = state.timer;
			if (!!timer && !!timer.start) {
				timer = { start: timer.start, active: true, pauses: timer.pauses }
			} else {
				timer = { start: time, active: true, pauses: [] }
			}
			
			timer.pauses = timer.pauses.map(item => {
				if (item.start && !item.end) {
					const diff = moment(moment().diff(moment.unix(item.start)))
					
					item.end = Number(diff.format('ss')) + Number(diff.format('mm')) * 60
				}
				
				return item;
			})
			
			state.timer = timer;
			
			localStorage.setItem(LOCAL_STORE_TIMER, JSON.stringify(state.timer));
		},
		
		stopGame(state) {
			state.status = false
			state.timer = {}
			
			localStorage.setItem(LOCAL_STORE_TIMER, JSON.stringify(state.timer));
			localStorage.setItem(LOCAL_STORE_STATUS, state.status);
		},
		
		changePlayers(state, players) {
			state.players = players
			
			state.stocks = state.stocks.map(item => {
				item.members = item.members.map((member, i) => {
					member.name = players[i];
					
					return member;
				})
				
				return item;
			})
			
			localStorage.setItem(LOCAL_STORE_PlAYERS, JSON.stringify(state.players));
			localStorage.setItem(LOCAL_STORE_STOCK, JSON.stringify(state.stocks));
		},
		
		pauseGame(state) {
			state.timer.active = false;
			state.timer.pauses = [ ...state.timer.pauses, { start: moment().unix() } ]
			
			localStorage.setItem(LOCAL_STORE_TIMER, JSON.stringify(state.timer));
		},
		
		addStock(state) {
			state.stocks.push(defaultStock(state.players))
			
			localStorage.setItem(LOCAL_STORE_STOCK, JSON.stringify(state.stocks));
		},
		
		addPassive(state) {
			state.passives.push(defaultPassive())
			
			localStorage.setItem(LOCAL_STORE_PASSIVE, JSON.stringify(state.passives));
		},
		
		addMember(state, index) {
			if (!state.stocks[index].members) {
				state.stocks[index].members = [];
			}
			
			state.stocks[index].members.push(defaultMember())
			
			localStorage.setItem(LOCAL_STORE_STOCK, JSON.stringify(state.stocks));
		},
		
		changeStock(state, data) {
			const selfStock = state.stocks[data.index]
			let selfMembers = selfStock.members;
			
			state.stocks[data.index][data.name] = data.value;
			
			/*if (data.name === 'price' && data.value > 0) {
				selfMembers = selfMembers.map(item => {
					if (item.count > 0 && !item.price) {
						item.price = selfStock.price * item.count;
					}
					
					if (item.price > 0 && !item.count) {
						item.count = item.price / selfStock.price;
					}
					
					return item;
				})
				
				state.stocks[data.index].members = selfMembers;
			}*/
			
			localStorage.setItem(LOCAL_STORE_STOCK, JSON.stringify(state.stocks));
		},
		
		changePassive(state, data) {
			state.passives[data.index][data.name] = data.value;
			
			localStorage.setItem(LOCAL_STORE_PASSIVE, JSON.stringify(state.passives));
		},
		
		changeSummary(state, data) {
			state.summary[data.name] = data.value;
			
			localStorage.setItem(LOCAL_STORE_SUMMARY, JSON.stringify(state.summary));
		},
		
		changeMember(state, data) {
			const selfStock = state.stocks[data.index]
			let selfMember = selfStock.members[data.ind];
			
			selfMember[data.name] = data.value;
			
			if (data.name == 'count') {
				if (selfMember.count > 0) {
					if (selfStock.price > 0) {
						selfMember.price = selfStock.price * selfMember.count;
					}
				} else {
					selfMember.price = '';
				}
			}
			
			if (data.name == 'price') {
				if (selfMember.price > 0) {
					if (selfStock.price > 0) {
						selfMember.count = selfMember.price / selfStock.price;
					}
				} else {
					selfMember.count = '';
				}
			}
			
			state.stocks[data.index].members[data.ind] = selfMember
			
			localStorage.setItem(LOCAL_STORE_STOCK, JSON.stringify(state.stocks));
		},
		
		removeStock(state, index) {
			state.stocks.splice(index, 1);
			
			localStorage.setItem(LOCAL_STORE_STOCK, JSON.stringify(state.stocks));
		},
		
		removePassive(state, index) {
			state.passives.splice(index, 1);
			
			localStorage.setItem(LOCAL_STORE_PASSIVE, JSON.stringify(state.passives));
		},
		
		changeWin(state, data) {
			state.win = data;
			
			localStorage.setItem(LOCAL_STORE_WIN, JSON.stringify(state.win));
		}
	}
})
