<template>
  <a-affix :offset-top="0">
    <div class="summary-results">
      <div class="item border">
        <div class="name">
          Общий доход
        </div>
        <div class="sum">
          {{ `${ getAllSum }`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
        </div>
      </div>
      <div class="item border" :class="getPassiveSum < win.all || getBusinessSum < win.business || getBusinessSum == 0 ? 'blue' : 'green'">
        <div class="name">
          Пассивный доход
        </div>
        <div class="sum">
          {{ `${ getPassiveSum }`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          Общие траты
        </div>
        <div class="sum">
          {{ `${ getNalogs }`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
        </div>
      </div>
      <div class="item blue">
        <div class="name">
          Зарплата
        </div>
        <div class="sum">
          {{ `${ getSalary }`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
        </div>
      </div>
    </div>
  </a-affix>

  <div class="summary">
    <div class="item">
      <h2>Прибыль</h2>

      <div>
        <table>
          <tr>
            <th>Наименование</th>
            <th>Сумма</th>
          </tr>
          <tr>
            <td>Зарплата</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.salary)"
                  @change="(value) => handleChangeSummary('salary', value)"
              />
            </td>
          </tr>
          <tr>
            <td>Депозит</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.deposit)"
                  @change="(value) => handleChangeSummary('deposit', value)"
                  :placeholder="`min сумма: ${String((win.all - getPassiveSum) * 100 / 5).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`"
              />
              <div class="min" v-if="getDepositSum > 0">
                Доход: {{ String(getDepositSum).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
              </div>
              <br>
              &nbsp;
            </td>
          </tr>

          <tr>
            <th colspan="2">Пассивный доход
              <a-tag v-if="getBusinessSum >= win.business && getBusinessSum > 0" color="green">условие выполнено</a-tag>
            </th>
          </tr>
          <tr v-for="(passive, index) in passives">
            <td>
              <div class="sum">
                <span class="index">{{ index + 1 }})</span>
                <a-input :value="passive.name" @change="(value) => handleChangePassive(index, 'name', value)"/>
              </div>
            </td>
            <td>
              <div class="sum">
                <a-input-number
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                    :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                    :value="toFixed(passive.sum)"
                    @change="(value) => handleChangePassive(index, 'sum', value)"
                />

                <a-button @click="() => handleRemovePassive(index)">
                  <DeleteOutlined/>
                </a-button>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <div class="add-passive" @click="addPassive">
                <PlusOutlined/>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="item">
      <h2>Кредит</h2>

      <div>
        <table>
          <tr>
            <td class="index">Сумма</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.credit)"
                  @change="(value) => handleChangeSummary('credit', value)"
              />
            </td>
          </tr>
        </table>
      </div>

      <h2>Траты</h2>
      <div>
        <table>
          <tr>
            <th>Наименование</th>
            <th>Сумма</th>
          </tr>
          <tr>
            <td class="index">Налог</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog1)"
                  @change="(value) => handleChangeSummary('nalog1', value)"
              />
            </td>
          </tr>
          <tr>
            <td class="index">Ипотека</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog2)"
                  @change="(value) => handleChangeSummary('nalog2', value)"
              />
            </td>
          </tr>
          <tr>
            <td class="index">Образование</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog3)"
                  @change="(value) => handleChangeSummary('nalog3', value)"
              />
            </td>
          </tr>
          <tr>
            <td class="index">Путешествие</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog4)"
                  @change="(value) => handleChangeSummary('nalog4', value)"
              />
            </td>
          </tr>
          <tr>
            <td class="index">Карты</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog5)"
                  @change="(value) => handleChangeSummary('nalog5', value)"
              />
            </td>
          </tr>
          <tr>
            <td class="index">Прочие</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog6)"
                  @change="(value) => handleChangeSummary('nalog6', value)"
              />
            </td>
          </tr>
          <tr>
            <td class="index">Авто</td>
            <td>
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="toFixed(summary.nalog7)"
                  @change="(value) => handleChangeSummary('nalog7', value)"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons-vue";

export default {
  name: 'SummaryView',
  components: { PlusOutlined, DeleteOutlined },
  computed: {
    ...mapGetters({
      passives: 'gamePassives',
      summary: 'gameSummary',
      win: 'gameWin'
    }),

    getAllSum() {
      return Number(this.getPassiveSum) + Number(this.summary.salary)
    },

    getDepositSum() {
      return this.summary.deposit > 0 ? this.summary.deposit / 100 * 5 : 0
    },

    getBusinessSum() {
      let sum = 0;

      this.passives.forEach(item => {
        sum += item.sum
      })

      return sum;
    },

    getPassiveSum() {
      let sum = 0;

      sum += this.getBusinessSum;
      sum += this.getDepositSum;

      return sum;
    },

    getNalogs() {
      return (this.summary.nalog1 || 0) +
          (this.summary.nalog2 || 0) +
          (this.summary.nalog3 || 0) +
          (this.summary.nalog4 || 0) +
          (this.summary.nalog5 || 0) +
          (this.summary.nalog7 || 0) +
          (this.summary.nalog6 || 0);
    },

    getSalary() {
      return this.getAllSum - this.getNalogs - Number(this.summary.credit / 10 || 0)
    },
  },
  methods: {
    ...mapActions([
      'addPassive',
      'changePassive',
      'changeSummary',
      'removePassive',
    ]),

    toFixed(val) {
      return !!val ? String(Number(val).toFixed(2)).replace(".00", "") : '';
    },

    handleRemovePassive(index) {
      this.$confirm({
        title: `Вы действительно хотите удалить пассив?`,
        okText: "Подтвердить",
        okType: "danger",
        cancelText: "Отменить",
        onOk: async () => {
          this.removePassive(index);
        }
      });
    },

    handleChangePassive(index, name, value) {
      this.changePassive({ index, name, value: !!value && !!value.target ? value.target.value : value })
    },

    handleChangeSummary(name, value) {
      this.changeSummary({ name, value: !!value && !!value.target ? value.target.value : value })
    },
  }
}
</script>