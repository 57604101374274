<template>
  <div class="stocks">
    <div class="item" v-for="(stock, index) in stocks" :class="{closed: !stock.opened}">
      <div class="title" v-if="!stock.opened" @click="() => handleChangeStock(index, 'opened', true)">
        <div class="name">
          <DownCircleOutlined/>

          <b>{{ stock.my ? 'Мой фонд' : 'Фонд' }}: {{ stock.name }}</b>
        </div>

        <span class="description">Стартовая сумма: <b>{{ stock.price || 0 }}</b>, Проценты: <b>{{
            stock.percent || 0
          }}</b></span>
      </div>
      <template v-else>
        <div class="title">
          <div class="name">
            <UpCircleOutlined v-if="!!stock.name" @click="() => handleChangeStock(index, 'opened', false)"/>

            <a-checkbox :checked="stock.my" @change="() => stock.my = !stock.my">Мой</a-checkbox>
            <a-input
                :value="stock.name"
                @change="(value) => handleChangeStock(index, 'name', value)"
                placeholder="Название"
            />

            <a-button type="primary" @click="() => handleRemoveStock(index)">Удалить</a-button>
          </div>

          <div class="settings">
            <a-input-number
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                :value="stock.price"
                @change="(value) => handleChangeStock(index, 'price', value)"
                placeholder="Цена покупки"/>
            <div class="input-wrap">
              <a-input-number
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                  :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                  :value="stock.percent"
                  @change="(value) => handleChangeStock(index, 'percent', value)"
                  placeholder="Процент"/>
            </div>
            <a-input-number
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                :value="stock.close"
                @change="(value) => handleChangeStock(index, 'close', value)"
                placeholder="Цена продажи"/>
          </div>
        </div>

        <div class="members">
          <h4>Участники</h4>

          <table width="100%" cellspacing="0" cellpadding="0">
            <tr class="members-title" v-if="!!stock.members && stock.members.length > 0">
              <th>Имя</th>
              <th>Кол-во</th>
              <th>Покупка</th>
              <th>Расчет</th>
            </tr>

            <template v-for="(member, ind) in stock.members">
              <tr class="member" v-if="!!member && !!member.name && !!member.name.name && member.name.name != ''">
                <td>
                  <span class="player">{{ member.name.name }}</span>
                </td>

                <td>
                  <a-input-number
                      :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                      :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                      :value="toFixed(member.count)"
                      @change="(value) => handleChangeMember(index, ind, 'count', value)"
                  />
                </td>
                <td>
                  <a-input-number
                      :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                      :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                      :value="toFixed(member.price)"
                      @change="(value) => handleChangeMember(index, ind, 'price', value)"
                  />
                </td>
                <td>
                  <div class="sale-price">
                    <a-input-number
                        :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                        :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                        :value="toFixed(getSalePrice(stock, member))"
                    />
                    <div class="small">Прибыль:
                      {{
                        String(getMySale(getSalePrice(stock, member), stock.close, member.count)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }}
                    </div>
                  </div>
                </td>
                <td>
                  <a-checkbox :checked="member.check"
                              @change="(value) => handleChangeMember(index, ind, 'check', value)"/>
                </td>
              </tr>
            </template>
          </table>

          <a-button v-if="0" shape="circle" @click="() => addMember(index)">
            <PlusOutlined/>
          </a-button>
        </div>

        <div class="summary">
          <div class="end">Кол-во:<br>
            <a-input-number
                :disabled="true"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                :value="toFixed(getCountAll(stock))"
            />
          </div>
          <div class="end gray">Покупка:<br>
            <a-input-number
                :disabled="true"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                :value="toFixed(getPriceAll(stock))"
            />
          </div>
          <div class="end blue">Расчет:<br>
            <a-input-number
                :disabled="true"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
                :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
                :value="toFixed(getCloseAll(stock))"
            />
          </div>
        </div>
      </template>
    </div>

    <div class="item new" @click="addStock">
      <div>
        <PlusOutlined/>
      </div>
      <p>Добавить фонд</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  UpCircleOutlined,
  DownCircleOutlined,
  PlusOutlined
} from "@ant-design/icons-vue";

export default {
  name: 'StocksView',
  components: { PlusOutlined, UpCircleOutlined, DownCircleOutlined },
  computed: {
    ...mapGetters({
      stocks: 'gameStocks',
    })
  },
  methods: {
    ...mapActions([
      'addStock',
      'removeStock',
      'changeStock',
      'addMember',
      'closeStock',
      'changeMember'
    ]),

    toFixed(val) {
      return !!val ? String(Number(val).toFixed(2)).replace(".00", "") : '';
    },

    getSalePrice(stock, member, isPercent = true) {
      if (!!member.name && !!member.name.isMe && stock.my && typeof stock.my !== 'undefined' || !isPercent) {
        return stock.close * member.count;
      } else {
        return stock.close * member.count * (!!stock.percent ? ((100 - stock.percent) / 100) : 1);
      }
    },

    getMySale(sale, close, count) {
      return this.toFixed(close * count - sale);
    },

    getPriceAll(stock) {
      let price = 0;

      stock.members.forEach(member => {
        if (!!member.price && member.price > 0) {
          price += member.price
        }
      })

      return price;
    },

    getCountAll(stock) {
      let count = 0;

      stock.members.forEach(member => {
        if (!!member.count && member.count > 0) {
          count += member.count
        }
      })

      return count;
    },

    getCloseAll(stock) {
      let price = 0;

      stock.members.forEach(member => {
        if (!!member.count && member.count > 0) {
          price += this.getSalePrice(stock, member, false)
        }
      })

      return price;
    },

    handleRemoveStock(index) {
      this.$confirm({
        title: `Вы действительно хотите удалить фонд?`,
        okText: "Подтвердить",
        okType: "danger",
        cancelText: "Отменить",
        onOk: async () => {
          this.removeStock(index);
        }
      });
    },

    handleChangeStock(index, name, value) {
      this.changeStock({ index, name, value: !!value && !!value.target ? value.target.value : value })
    },

    handleChangeMember(index, ind, name, value) {
      this.changeMember({
        index, ind, name, value: !!value && !!value.target
            ? value.target.checked
                ? value.target.checked
                : value.target.value
            : value
      })
    },
  }
}
</script>
