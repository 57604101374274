<template>
  <a-modal
      class="players-modal"
      :open="modals.players"
      :onOk="handleSavePlayers"
      :onCancel="handleHideForm"
      title=""
  >
    <div class="players-wrap">
      <div class="ant-modal-title">Условия победы?</div>
      <h5>Доход с предприятий</h5>
      <a-input-number
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
          :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
          :value="win.business"
          @change="(value) => handleChangeWin('business', value)"
      />
      <h5>Общий пассивный доход</h5>
      <a-input-number
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
          :parser='value => value.replace(/\$\s?|(\s*)/g, "").replace(",", ".")'
          @change="(value) => handleChangeWin('all', value)"
          :value="win.all"
      />
    </div>
    <div class="ant-modal-title">С кем играешь?</div>
    <table width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr>
        <th></th>
        <th style="text-align: left">Имя игрока</th>
        <th>Я</th>
      </tr>


      <tr v-for="(player, i) in selfPlayers">
        <td>
          {{ i + 1 }}.
        </td>
        <td>
          <a-input v-model:value="player.name" placeholder="Имя игрока"/>
        </td>
        <td>
          <a-checkbox :checked="player.isMe" @change="(value) => {player.isMe = value.target.checked}"/>
        </td>
      </tr>
    </table>
  </a-modal>

  <a-tour v-model:current="tour.current" :open="tour.open" :steps="tour.steps" @close="handleTour(false)"/>

  <template v-if="status">
    <router-view/>
  </template>

  <div v-else class="block-start-play">
    <div class="text">
      <a v-if="0" @click="handleTour(true)">пройди обучение</a>

      <p>НАЖМИ ДЛЯ НАЧАЛА ИГРЫ</p>
    </div>
    <ArrowDownOutlined/>
  </div>

  <footer>
    <nav>
      <div v-if="!status" class="block-start-play"/>
      <div class="item" @click="() => modals.players = true">
        <TeamOutlined ref="ref1"/>
        Игроки, условия
      </div>
      <router-link to="/stocks" class="item">
        <AuditOutlined ref="ref2"/>
        Фонды
      </router-link>
      <div class="item center" :class="{played: status}">
        <div class="icons">
          <PlayCircleOutlined ref="ref3" v-if="!status" @click="handleStartGame"/>
          <template v-else>
            <PauseCircleOutlined v-if="!!selfTimer && selfTimer.active" @click="pauseGame"/>
            <PlayCircleOutlined v-else @click="startTimer"/>

            <span class="anticon">
              <svg @click="stopGame" width="50" height="50" viewBox="0 0 50 50" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <rect x="1.5" y="1.5" width="47" height="47" rx="23.5" stroke="currentColor" stroke-width="3"/>
                <rect x="15" y="15" width="20" height="20" rx="2" fill="currentColor"/>
              </svg>
            </span>
          </template>
        </div>

        <template v-if="status">
          <div class="timer">
            Время: <b>{{ !!selfTimer.counter ? selfTimer.counter.format('H:mm:ss') : '...' }}</b>
          </div>
        </template>
      </div>
      <router-link to="/summary" class="item">
        <BookOutlined ref="ref4"/>
        Отчет
      </router-link>
      <div class="item clear" @click="handleClearAll">
        <ClearOutlined ref="ref5"/>
        Стереть
      </div>
    </nav>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  TeamOutlined,
  ClearOutlined,
  AuditOutlined,
  BookOutlined,
  DollarOutlined,
  ArrowDownOutlined,
  CopyOutlined
} from '@ant-design/icons-vue';
import moment from "moment";
import lodash from "lodash";
import { ref, createVNode } from 'vue';

let timerInterval = '';

export default {
  name: 'AppCashFlow',
  components: {
    PlayCircleOutlined,
    PauseCircleOutlined,
    TeamOutlined,
    ClearOutlined,
    AuditOutlined,
    BookOutlined,
    DollarOutlined,
    ArrowDownOutlined,
    CopyOutlined
  },
  computed: {
    ...mapGetters({
      status: 'gameStatus',
      players: 'gamePlayers',
      timer: 'gameTimer',
      win: 'gameWin'
    }),
  },

  data: function () {
    return {
      modals: {
        players: false,
        timeOff: false
      },
      selfPlayers: [],
      selfTimer: {},
      tour: {
        current: ref(0),
        open: false,
        steps: []
      }
    }
  },

  watch: {
    players() {
      this.selfPlayers = this.players;
    },

    timer: {
      handler() {
        this.selfTimer = lodash.cloneDeep(this.timer);
        this.getTime()
      },
      deep: true
    }
  },

  created() {
    this.selfPlayers = this.players;
    this.selfTimer = lodash.cloneDeep(this.timer);
    this.getTime()
  },

  methods: {
    ...mapActions([
      'startGame',
      'pauseGame',
      'stopGame',
      'changePlayers',
      'startTimer',
      'changeWin',
      'clearAll'
    ]),

    handleTour(param) {
      if (param) {
        this.tour.steps = [
          {
            title: 'Upload File',
            description: 'Put your files here.',
            cover: createVNode('img', {
              alt: 'tour.png',
              src: 'https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png',
            }),
            target: () => this.$refs.ref1,
          },
          {
            title: 'Save',
            description: 'Save your changes.',
            target: () => this.$refs.ref2,
          },
          {
            title: 'Other Actions',
            description: 'Click to see other actions.',
            target: () => this.$refs.ref3,
          },
        ]
      }

      this.tour.open = param
    },

    handleChangeWin(name, value) {
      this.changeWin({ ...this.win, [name]: !! value && !!value.target ? value.target.value : value })
    },

    handleClearAll() {
      this.$confirm({
        title: `Вы действительно хотите стереть данные игры?`,
        okText: "Подтвердить",
        okType: "danger",
        cancelText: "Отменить",
        onOk: async () => {
          this.clearAll();
        }
      });
    },

    handleSavePlayers() {
      this.changePlayers(this.selfPlayers);
      this.startGame();
      this.modals.players = false;
    },

    handleHideForm() {
      this.modals.players = false;
      this.selfPlayers = this.players;
    },

    handleStartGame() {
      this.modals.players = true;

      if (timerInterval) {
        clearInterval(timerInterval);
      }

      this.startTimer(moment().unix());
    },

    getTime() {
      if (!!this.selfTimer && this.selfTimer.active) {
        if (timerInterval) {
          clearInterval(timerInterval);
        }

        let pauses = 0;
        if (this.selfTimer.pauses) {
          this.selfTimer.pauses.forEach(item => {
            if (!!item.start && !!item.end) {
              pauses += item.end
            }
          })
        }

        timerInterval = setInterval(() => {
          if (this.selfTimer.active) {
            const counter = moment(moment().diff(moment.unix(this.selfTimer.start)));
            this.selfTimer.counter = moment.unix(
                (
                    //Number(counter.format('hh')) * 60 * 60 +
                    Number(counter.format('ss')) +
                    Number(counter.format('mm')) * 60
                ) -
                pauses -
                moment().utcOffset() * 60);
          }
        }, 1000);
      } else if (!!this.selfTimer.counter) {
        this.selfTimer.counter = moment(this.selfTimer.counter);
      }

      return '';
    }
  }
}
</script>

<style lang="less">
@import "styles/public";
</style>
